import React, { useEffect } from 'react';
import { Text, TextVariant } from '@naf/text';
import { Label } from '@naf/label';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { nafCenterBasePath } from '../../../../routers/NafCenterRouter';
import { CenterProducts } from '../../../../../../types/centerArticleType';
import S from '../styles';
import useSelector from '../../../../redux/typedHooks';
import { useAuth0Token } from '../../../../hooks/useAuth0Token';
import { actions as MyMembershipActions } from '../../../../redux/modules/myMembership';
import BookingButton from './BookingButton';

export const NafCenterProduct = ({
  productId,
  ingress,
  memberPrice,
  name,
  nonMemberPrice,
  booking,
  bookingText,
  isBeta,
  isNew,
  isForMembersOnly,
  link,
  linkText,
}: CenterProducts) => {
  const dispatch = useDispatch();
  const { simpleToken } = useAuth0Token();
  const customerInformation = useSelector((state) => state.myMembership.customerInformation.data);

  useEffect(() => {
    if (simpleToken && !customerInformation) {
      dispatch(MyMembershipActions.getCustomerInformation.request(simpleToken));
    }
  }, [simpleToken, dispatch, customerInformation]);

  return (
    <S.ProductRow key={productId}>
      <S.ProductInfo>
        <S.ProductHeader>
          <Text variant={TextVariant.Header3}>{name}</Text>
          {((memberPrice && memberPrice.isCampaign) || (nonMemberPrice && nonMemberPrice.isCampaign)) && (
            <Label variant="moss" text="Kampanje" />
          )}
          {isNew && <Label variant="moss" text="Nyhet" />}
          {isBeta && <Label variant="info" text="Beta" />}
          {isForMembersOnly && <Label variant="signature" text="Medlemseksklusivt" />}
        </S.ProductHeader>
        <Text variant={TextVariant.BodyText}>{ingress}</Text>
        {link && link.data && link.type === 'internalLink' && (
          <Link to={`/${nafCenterBasePath}/${link.data.slug}`}>
            <Text variant={TextVariant.BodyText}>{linkText || `Les mer om ${name}`}</Text>
          </Link>
        )}
      </S.ProductInfo>
      <S.OrderProduct>
        {memberPrice &&
          (memberPrice.isCampaign && memberPrice.campaignPrice ? (
            <S.PriceRow>
              <Text variant={TextVariant.Header3}>{memberPrice.campaignPrice},- </Text>
              {memberPrice.price && (
                <S.OrdinaryPrice variant={TextVariant.Header3}>{memberPrice.price},-</S.OrdinaryPrice>
              )}
            </S.PriceRow>
          ) : (
            memberPrice.price && <Text variant={TextVariant.Header3}>{memberPrice.price},-</Text>
          ))}
        {memberPrice &&
          nonMemberPrice &&
          (nonMemberPrice.isCampaign && nonMemberPrice.campaignPrice ? (
            <S.PriceRow>
              <S.NonMemberPrice variant={TextVariant.BodyTextHeader}>Ikke-medlem</S.NonMemberPrice>
              <S.NonMemberCampaign>
                <S.NonMemberPrice variant={TextVariant.BodyTextHeader}>
                  {nonMemberPrice.campaignPrice},-{' '}
                </S.NonMemberPrice>
                {nonMemberPrice.price && (
                  <S.OrdinaryPrice variant={TextVariant.BodyTextHeader}>{nonMemberPrice.price},-</S.OrdinaryPrice>
                )}
              </S.NonMemberCampaign>
            </S.PriceRow>
          ) : (
            nonMemberPrice.price && (
              <S.NonMemberPrice variant={TextVariant.BodyTextHeader}>
                Ikke-medlem {nonMemberPrice.price},-
              </S.NonMemberPrice>
            )
          ))}
        {!memberPrice &&
          nonMemberPrice &&
          (nonMemberPrice.isCampaign && nonMemberPrice.campaignPrice ? (
            <S.PriceRow>
              <Text variant={TextVariant.Header3}>{nonMemberPrice.campaignPrice},- </Text>
              {nonMemberPrice.price && (
                <S.OrdinaryPrice variant={TextVariant.Header3}>{nonMemberPrice.price},-</S.OrdinaryPrice>
              )}
            </S.PriceRow>
          ) : (
            nonMemberPrice.price && <Text variant={TextVariant.Header3}>{nonMemberPrice.price},-</Text>
          ))}
        <BookingButton booking={booking} bookingText={bookingText} link={link} />
      </S.OrderProduct>
    </S.ProductRow>
  );
};
