import styled, { css } from 'styled-components';
import { spacing, breakpoints } from '@naf/theme';
import { GridCol, GridRow } from '@naf/grid';
import { Text } from '@naf/text';
import { Button } from '@naf/button';
import { nafColor } from '@nafcore/theme';
import { UserInteraction } from '@naf/user-interaction';
import { ButtonLink } from '@naf/button-link';

const ImageColWrapper = styled.div`
  width: 100%;
  max-height: 350px;
  overflow: hidden;
  margin-top: ${spacing.space32};

  & > img {
    width: 100%;
    height: 350px;
    object-fit: cover;

    @media (max-width: ${breakpoints.m}) {
      height: 284px;
    }
    @media (max-width: ${breakpoints.s}) {
      height: 216px;
    }
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100vw;
    margin-left: -${spacing.space24};
  }
`;

const Img = styled.img`
  width: 100%;
  display: flex;
  object-fit: contain;
  max-width: 100%;

  @media (max-width: ${breakpoints.m}) {
    /* 16:9 scale */
    height: 56vw;
    width: 100vw;
    object-fit: cover;
  }
`;

const PageCol = styled(GridCol)<{ $hasImage?: boolean }>`
  grid-column-start: 3;
  background-color: ${nafColor.signature.white};
  padding: ${spacing.space24} ${spacing.space48} 0;
  margin: 0 -${spacing.space48};

  h1 {
    margin: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    grid-column-start: auto;
    padding: ${spacing.space8} ${spacing.space24} 0;
    margin: 0 -${spacing.space12};
  }

  @media (max-width: ${breakpoints.s}) {
    padding: ${spacing.space8} ${spacing.space12} 0;
  }

  ${({ $hasImage }) =>
    $hasImage &&
    css`
      margin-top: -100px;

      @media (max-width: ${breakpoints.m}) {
        margin-top: -76px;
      }
      @media (max-width: ${breakpoints.s}) {
        margin-top: -63px;
      }
    `}
`;

const TagsRow = styled(GridRow)`
  width: 100%;
  margin-top: ${spacing.space24};
`;

const Tag = styled.div<{ iconSize?: number }>`
  display: flex;
  align-items: center;

  svg {
    color: ${nafColor.primary.park};
    margin-right: ${spacing.space12};
  }
  p {
    margin: 0;
    font-weight: 400;
    white-space: nowrap;
  }

  ${({ iconSize }) =>
    iconSize &&
    css`
      svg {
        min-width: ${iconSize}px;
        min-height: ${iconSize}px;
        max-width: ${iconSize}px;
        max-height: ${iconSize}px;
      }
    `}
`;

const AddressRow = styled(GridRow)`
  width: 100%;
  margin-top: ${spacing.space64};

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space32};
    grid-gap: ${spacing.space20} !important;
  }

  p,
  h5 {
    margin: 0;
  }
`;

const AddressCol = styled(GridCol)`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(ButtonLink)`
  margin-top: ${spacing.space8};
  width: fit-content;
`;

const HoursCol = styled(GridCol)`
  p {
    white-space: nowrap;
  }
`;

const Products = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.space80};

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space64};
  }

  h2 {
    margin-top: 0;
    margin-bottom: ${spacing.space16};
  }
`;

const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.space24};
  align-items: flex-end;

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space4};

    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-bottom: ${spacing.space24};
      margin-bottom: ${spacing.space20};
    }
  }

  &:not(:last-child) {
    padding-bottom: ${spacing.space32};
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    @media (max-width: ${breakpoints.m}) {
      padding-bottom: ${spacing.space24};
      margin-bottom: ${spacing.space20};
    }
  }

  h3,
  p {
    margin: 0;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 450px;

  p {
    margin-top: ${spacing.space4};
  }
  a {
    margin-top: ${spacing.space20};
  }

  @media (max-width: ${breakpoints.m}) {
    p {
      margin-top: ${spacing.space8};
    }
  }
`;

const ProductHeader = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: ${spacing.space24};
  }

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    align-items: flex-start;

    span {
      margin-left: 0;
      margin-top: ${spacing.space8};
    }
  }
`;

const OrderProduct = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.space24};

  h3,
  h6,
  p {
    margin: 0;
  }
`;

const PriceRow = styled.div`
  white-space: pre;
  h3 {
    display: inline-block;
  }
`;

const NonMemberCampaign = styled.div`
  display: flex;
`;

const OrdinaryPrice = styled(Text)`
  font-weight: 300;
  text-decoration: line-through;
  white-space: pre;
`;

const NonMemberPrice = styled(Text)`
  font-weight: 500;
  margin: ${spacing.space4} 0 0 0;
`;

const BookingButton = styled(Button)`
  margin-top: ${spacing.space12};
`;

const ShowAllWrapper = styled.div`
  margin-top: ${spacing.space40};
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.m}) {
    margin-top: 0;
  }

  p {
    margin: 0 0 0 ${spacing.space20};
  }
`;

const LogIn = styled.div`
  margin-top: ${spacing.space80};

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space64};
  }

  p {
    margin: 0 0 ${spacing.space4};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.space80};

  > p {
    margin: 0 0 ${spacing.space32};
  }

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space64};

    > p {
      margin: 0 0 ${spacing.space24};
    }
  }
`;

const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.space80};

  h3 {
    margin-bottom: ${spacing.space32};
  }
`;

const InfoBox = styled(UserInteraction)`
  margin-top: ${spacing.space32};

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space16};
  }
`;

export default {
  ImageColWrapper,
  Img,
  PageCol,
  TagsRow,
  Tag,
  AddressRow,
  AddressCol,
  StyledLink,
  HoursCol,
  Products,
  ProductRow,
  ProductInfo,
  ProductHeader,
  OrderProduct,
  PriceRow,
  OrdinaryPrice,
  NonMemberPrice,
  BookingButton,
  ShowAllWrapper,
  LogIn,
  Body,
  NonMemberCampaign,
  InformationWrapper,
  InfoBox,
};
