import { Table, TableBody, TableCell, TableRow } from '@naf/table';
import { Text, TextVariant } from '@naf/text';
import React from 'react';

interface TableRowType {
  _key: string;
  _type: string;
  cells: string[];
}

type Props = {
  table: {
    title: string;
    table: {
      rows: TableRowType[];
    };
  };
};

const CenterInformationTable = ({ table }: Props) => (
  <>
    <Text variant={TextVariant.Header3}>{table.title}</Text>
    <Table>
      <TableBody>
        {table.table.rows.map((row) => (
          <TableRow key={row._key}>
            {row.cells.map((cell, index) => (
              <TableCell key={cell} align="left" as={index === 0 ? 'th' : 'td'}>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </>
);

export default CenterInformationTable;
