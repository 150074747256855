import React from 'react';

interface Props {
  id?: string;
  title: string | null;
  openingTime: string | null;
  closingTime: string | null;
  openingTimeFriday?: string | null;
  closingTimeFriday?: string | null;
  address: string | null;
  city: string | null;
  coordinates: {
    longitude: string;
    latitude: string;
  } | null;
}

const SchemaCenter = ({
  id = '',
  title,
  openingTime,
  closingTime,
  openingTimeFriday,
  closingTimeFriday,
  address,
  city,
  coordinates,
}: Props) => {
  const structuredData = `{
        "@context": "http://schema.org",
		    "@type": "AutoRepair",
        "name": "${title}",
        "openingHours": ["Mo-To ${openingTime}-${closingTime}", "Fr ${openingTimeFriday}-${closingTimeFriday}"],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "${address}",
          "addressRegion": "${city}"
        },
        "location": {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "${address}",
            "addressRegion": "${city}"
          },
          "latitude": "${coordinates?.latitude}",
          "longitude": "${coordinates?.longitude}"
        },
        "keywords": [
          "bilreparasjon",
          "firehjulskontroll",
          "hjulstillingskontroll",
          "forstillingskontroll",
          "tilstandsrapport bil",
          "bruktbiltest",
          "bilservice",
          "service",
          "eu kontroll ${city}",
          "eu godkjenning ${city}",
          "etterkontroll ${city}",
          "oljeskift ${city}",
          "garantiutløpstest",
          "ac service",
          "air condition service",
          "verksted",
          "vedlikehold av bil",
          "motorreparasjon",
          "bremsetjeneste",
          "oljebytte",
          "girkassereparasjon",
          "dekksjekk",
          "bilmekaniker",
          "bildiagnostikk",
          "bilverksted",
          "kollisjonsreparasjon",
          "bilreparasjon i ${city}",
          "rimelig bilservice"
      ]
    }`;

  return (
    // eslint-disable-next-line react/no-danger
    <script type="application/ld+json" key={`centerJSON-${id}`} dangerouslySetInnerHTML={{ __html: structuredData }} />
  );
};

export default SchemaCenter;
