import React, { useRef } from 'react';
import { Text, TextVariant } from '@naf/text';
import { InformationTable } from '../../../../../../types/centerArticleType';
import S from '../styles';

export interface NafCenterOpenHoursProps {
  informationTable: InformationTable;
  openingTime: string;
  openingTimeFriday: string;
  closingTime: string;
  closingTimeFriday: string;
}

export const NafCenterOpenHours = ({
  informationTable,
  closingTime,
  closingTimeFriday,
  openingTime,
  openingTimeFriday,
}: NafCenterOpenHoursProps) => {
  const informationTableRef = useRef<HTMLDivElement>(null);
  const formatTime = (time: string) => time.slice(0, 5).replace(':', '.');

  return (
    <S.HoursCol s="12" m="12" l="6" xl="6">
      <Text variant={TextVariant.ArticleTextHeader}>Åpningstider:</Text>
      <Text>
        mandag - torsdag: {formatTime(openingTime)}-{formatTime(closingTime)}
      </Text>
      <Text>
        fredag: {formatTime(openingTimeFriday)}-{formatTime(closingTimeFriday)}
      </Text>

      {informationTable && (
        <Text>
          <S.StyledLink
            onClick={() => informationTableRef?.current?.scrollIntoView({ behavior: 'smooth' })}
            text="Se avvikende åpningstider"
            variant="secondary"
            size="small"
          />
        </Text>
      )}
    </S.HoursCol>
  );
};
