import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { TextList, TextListItem } from '@naf/text-list';

import { CenterArticleType } from '../../../../../../types/centerArticleType';
import S from '../styles';

export const CenterInformation = ({ title, additionalInformation }: Partial<CenterArticleType>) => (
  <S.InformationWrapper>
    <Text variant={TextVariant.Header3}>Informasjon om {title}</Text>
    <TextList maxWidth={512}>
      {additionalInformation &&
        additionalInformation.map((info: string) => <TextListItem key={info}>{info}</TextListItem>)}
    </TextList>
  </S.InformationWrapper>
);
