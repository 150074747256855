import React from 'react';
import { GridCol } from '@naf/grid';
import { Text } from '@naf/text';
import { Build } from '@styled-icons/material-outlined/Build';
import { HomeRepairService } from '@styled-icons/material-outlined/HomeRepairService';
import { Refresh } from '@styled-icons/material-outlined/Refresh';
import { CheckCircle } from '@styled-icons/material-outlined/CheckCircle';
import S from '../styles';

const RenderIcon = ({ icon }: { icon: string }) => {
  switch (icon) {
    case 'HomeRepairServiceOutlinedIcon':
      return <HomeRepairService size={20} />;
    case 'RefreshOutlinedIcon':
      return <Refresh size={20} />;
    case 'BuildOutlinedIcon':
      return <Build size={20} />;
    case 'CheckCircleOutlineOutlinedIcon':
      return <CheckCircle size={20} />;
    default:
      return null;
  }
};

export const NafCenterTag = ({ description, icon }: { description: string; icon: string }) => (
  <GridCol s="6" m="6" l="3" xl="3">
    <S.Tag iconSize={20}>
      <RenderIcon icon={icon} />
      <Text>{description}</Text>
    </S.Tag>
  </GridCol>
);
