import React from 'react';
import { useHistory } from 'react-router-dom';
import S from '../styles';
import { nafCenterBasePath } from '../../../../routers/NafCenterRouter';

interface BookingButtonProps {
  booking: any;
  bookingText: string;
  link: any;
}

const BookingButton = ({ booking, bookingText, link }: BookingButtonProps) => {
  const history = useHistory();

  return booking && booking.data.href && booking.type === 'externalLink' ? (
    <S.BookingButton href={booking.data.href} target={booking.data.blank ? '_blank' : '_self'}>
      Finn ledig tid
    </S.BookingButton>
  ) : (
    <S.BookingButton
      type="button"
      onClick={() => {
        history.push(link ? `/${nafCenterBasePath}/${link.data.slug}` : 'https://www.naf.no/kontakt-oss/', {
          custom: true,
        });
      }}
    >
      {bookingText || 'Be om tilbud'}
    </S.BookingButton>
  );
};

export default BookingButton;
