import React from 'react';
import { Text, TextVariant } from '@naf/text';
import S from '../styles';

export interface NafCenterAddressProps {
  address: string;
  city: string;
  coordinates: {
    latitude: string;
    longitude: string;
  };
}

export const NafCenterAddress = ({ address, city, coordinates }: NafCenterAddressProps) => (
  <S.AddressCol s="12" m="12" l="6" xl="6">
    <Text variant={TextVariant.ArticleTextHeader}>Adresse:</Text>
    <Text>
      {address}
      {city && `, ${city}`}
    </Text>
    {coordinates && (
      <S.StyledLink
        href={`http://www.google.com/maps/place/${coordinates?.latitude},${coordinates?.longitude}`}
        target="_blank"
        variant="secondary"
        size="small"
        text="Få veibeskrivelse"
      />
    )}
  </S.AddressCol>
);
